<template>
  <div class="page">
    <div class="top flex_align"><span class="text">可选寝室</span></div>
    <div class="flex_column flex_align" style="margin-bottom: 40px">
      <div class="item" v-for="item in room" :key="item.id">
        <div class="flex_between">
          <div class="v_b">
            <div class="num">{{ item.name }}<span>室</span></div>
            <div class="type flex_align" :class="`type${item.type}`">{{ item.num }}人间</div>
          </div>
          <div class="free v_b">
            剩余{{ item.free }}个床位
          </div>
        </div>
        <div class="flex_up remark" v-if="item.bz">
          <div>提醒：</div>
          <div class="flex_1">{{ item.bz }}</div>
        </div>
        <div class="">
          <van-button class="gradient_button gradient_button_blue" :loading="item.buttonLoading" @click="checkIn(item)" :disabled="loading">立即入住</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getBedroom, intoBed } from '@/api/dormitory'

export default {
  name: 'chooseDormitoryRoom',
  data () {
    return {
      loading: false,
      room: [
        { id: 1, name: 101, type: 4, free: 4, buttonLoading: false },
        { id: 2, name: 102, type: 2, free: 1, buttonLoading: false },
        { id: 3, name: 102, type: 2, free: 1, buttonLoading: false },
        { id: 4, name: 102, type: 2, free: 1, buttonLoading: false },
        { id: 5, name: 102, type: 2, free: 1, buttonLoading: false },
        { id: 6, name: 102, type: 2, free: 1, buttonLoading: false },
        { id: 7, name: 102, type: 2, free: 1, buttonLoading: false },
        { id: 8, name: 102, type: 2, free: 1, buttonLoading: false }
      ],
      clazzId: 0,
      sex: -1,
      apartmentId: -1,
      edu: -1,
      userInfo: JSON.parse(window.localStorage.getItem('userInfo'))
    }
  },
  activated () {
    const userInfo = this.userInfo
    const d = {
      clazzId: userInfo.clazzId,
      sex: userInfo.sex,
      edu: userInfo.edu,
      apartmentId: Number(this.$route.query.area)
    }
    const dataMap = ['clazzId', 'sex', 'apartmentId', 'edu']
    let refresh = false
    for (let i = 0; i < dataMap.length; i++) {
      const item = dataMap[i]
      if (d[item] !== this[item]) {
        this[item] = d[item]
        refresh = true
      }
    }
    if (refresh) {
      this.getData()
    }
  },
  methods: {
    getData () {
      this.room = []
      const userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
      getBedroom({ sex: this.sex, apartmentId: this.apartmentId, clazzId: userInfo.clazzId, edu: this.userInfo.edu }).then(res => {
        const { data: r } = res
        const { msg, data, code } = r
        if (code === 0) {
          this.room = data.map(item => {
            const { id, name, residue: free, num: type, bz } = item
            return { id, name, free, type: type % 2, num: type, buttonLoading: false, bz }
          })
        } else {
          this.$toast.fail(msg)
          this.$router.back()
        }
      })
    },
    checkIn (item) {
      if (this.loading === true) { return }
      this.$dialog.alert({
        title: `确定入住${item.name}室吗?`,
        confirmButtonColor: '#1677ff',
        showCancelButton: true,
        beforeClose: (action, done) => {
          if (action === 'confirm') {
            done()
            item.buttonLoading = true
            this.loading = true
            const userInfo = this.userInfo
            setTimeout(() => {
              intoBed({ bedroomId: item.id, studentId: userInfo.id, clazzId: userInfo.clazzId, edu: userInfo.edu }).then(res => {
                const { data: r } = res
                const { msg, code } = r
                if (code === 0) {
                  this.$router.push('/dormitory/checkInfo')
                } else {
                  this.$toast.fail(msg)
                }
                item.buttonLoading = false
                this.loading = false
              })
            }, 500)
          } else {
            done()
          }
        }
      }).then().catch(() => {})
    }
  }
}
</script>

<style scoped lang="less">
@import "../../assets/button";
.page {
  background: #F3F3F3;
  .top {
    height: 25px;
    padding: 15px 40px;
    .text {
      font-size: 14px;
      color: #000;
      position: relative;
      &::before {
        content: '';
        width: 4px; height: 19px;
        background: #75AFFE;
        border-radius: 1px;
        position: absolute;
        left: -20px; top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .item {
    width: 295px;
    padding: 10px 20px 15px 20px;
    background: #FFF;
    box-shadow: 0 3px 5px rgba(187, 214, 251, 0.16);
    border-radius: 12px;
    &:not(:last-child) {
      margin-bottom: 15px;
    }
    .v_b {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
    }
    .free {
      color: #75AFFE;
      font-size: 12px;
    }
    .num {
      color: #75AFFE;
      font-size: 30px;
      margin-right: 15px;
      transform: translateY(6px);
      & span {
        font-size: 18px;
      }
    }
    .type {
      padding: 2px 6px;
      font-size: 9px;
      border-radius: 4px;
      &.type0 {
        color: #FFF;
        background: #1CB394;
      }
      &.type1 {
        color: #FFF;
        background: #85A2FB;
      }
    }
    .remark {
      margin-top: 15px;
      color: #999;
      font-size: 14px;
      word-break: break-all;
    }
  }
}

.gradient_button {
  height: 33px;
  margin-top: 15px;
  &/deep/ .van-button__text {
    font-size: 12px;
  }
}
</style>
